//const databaseUrl="http://localhost:9000/api";
//const iaBaseUrl ="http://127.0.0.1:5000/ad-data";

//const databaseUrl= "http://186.101.139.68/api";
// Solo necesitas una de estas dos declaraciones, elige la que necesites según tu entorno

// Si estás trabajando localmente, usa esta URL


// Si estás trabajando en un entorno de producción, usa esta URL
// const databaseUrl = "http://186.101.139.68:6001/api";


// const databaseUrl = "http://localhost:6001/api";


// const databaseUrl = "http://52.67.9.147:6001/api";
const databaseUrl = "https://recruiters.match-working.com/backend/api";
// const databaseUrl = "http://127.0.0.1:6001/api";
// const databaseUrl = "http://157.100.244.57:6001/api";

// const iaBaseUrl = "http://157.100.244.57:6002/ia";
const iaBaseUrl = "https://recruiters.match-working.com/integrationia/ia";

// const walletUrl = "http://52.67.9.147:6003/api/v1/wallet";
const walletUrl = "https://recruiters.match-working.com/wallet/api/v1/wallet";
// const walletUrl ="http://157.100.244.57:6003/api/v1/wallet";
// const walletUrl ="http://localhost:6002/api/v1/wallet";

// const cvFilesUrl = "http://157.100.244.57:6004/"
const cvFilesUrl = "https://recruiters.match-working.com/converter/";
// const cvFilesUrl = "http://127.0.0.1:8000/"
const uploadCandidate = "https://xquscx548g.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/candidate/create"
const perfilCargoFormUrl = "https://j40yyu6c7b.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/profile/create"
const ponderacionPerfilCargoUrl = "https://0lrkx70rv9.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/profile/params"
const urlGetAnalisis = "https://5u4wipi169.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/analysis/profile/"
//https://5u4wipi169.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/analysis/profile/0fc88676-0b0b-4d94-b586-d9c6d4ba3fbb

// const iaBaseUrl = "http://127.0.0.1:5000/ia";
const execAnalizeUrl = "https://bc28kfcpg5.execute-api.sa-east-1.amazonaws.com/ia/analizador/procesar/candidatos"
const execAnalizeUrl2 = "http://157.100.244.57:6002/ia/analizador/procesar/candidatos"


/*const cvFilesUploadUrl = "http://157.100.244.57:6004/uploadfile/"
const perfilCargoFormUrl = "https://j40yyu6c7b.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/profile/create"
const ponderacionPerfilCargoUrl = "https://0lrkx70rv9.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/profile/params"
const urlGetAnalisis = "https://5u4wipi169.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/"*/

const profilebyUser = 'https://j40yyu6c7b.execute-api.sa-east-1.amazonaws.com/api/v1/analizer/profile/by/user';
//Token credentials
const credentials = {
    clientId:"3d9fb48af3a2cc68e2e687256ac5cf28",
    clientSecret:"190e67c525c896f3619d36514e6bd26c"
}  

const copyToClipboardProfile = 'http://localhost:3000/form-cv'

const userId = ""
module.exports = { 
    databaseUrl, 
    userId, 
    iaBaseUrl,
    walletUrl,
    cvFilesUrl,
    perfilCargoFormUrl, 
    ponderacionPerfilCargoUrl,
    execAnalizeUrl,
    execAnalizeUrl2,
    urlGetAnalisis,
    uploadCandidate,
    profilebyUser,
    credentials,
    copyToClipboardProfile
};