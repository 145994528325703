import { useState } from "react";
import { CircularProgress } from "@mui/material";
import PointsPonderationInput from "./PointsPonderationInput";
import showAlert from "../../utilities/alerts";
import { lambdaProfile } from "../../enviroment/internal-lambdas";
import Swal from "sweetalert2";

const PonderationComponent = ({ setProfile_id, id, profile, profileUpdate, setPage }) => {

    const [formValues, setFormValues] = useState({
        experiencia: 0,
        educacion: 0,
        cursos: 0,
    });
    const [loading, setLoading] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        const total = formValues.experiencia + formValues.educacion + formValues.cursos;
        if (total === 0) {
            showAlert.error("Por favor, rellena los campos solicitados.")
            return;
        }
        //normalización /100
        const normalizedValues = {
            experiencia: (formValues.experiencia / total) * 100,
            educacion: (formValues.educacion / total) * 100,
            cursos: (formValues.cursos / total) * 100,
        };
        //redondeo
        const roundedValues = {
            experiencia: Math.round(normalizedValues.experiencia * 100) / 100,
            educacion: Math.round(normalizedValues.educacion * 100) / 100,
            cursos: Math.round(normalizedValues.cursos * 100) / 100,
        };

        let roundedTotal = roundedValues.experiencia + roundedValues.educacion + roundedValues.cursos;        
        //ajuste de perdida en redondeo
        if (roundedTotal !== 100) {
            const difference = Math.round((100 - roundedTotal) * 100) / 100;

            const decimalParts = {
                experiencia: roundedValues.experiencia % 1,
                educacion: roundedValues.educacion % 1,
                cursos: roundedValues.cursos % 1,
            };

            let maxDecimalKey = Object.keys(decimalParts).reduce((a, b) => decimalParts[a] > decimalParts[b] ? a : b);

            roundedValues[maxDecimalKey] = Math.round((roundedValues[maxDecimalKey] + difference) * 100) / 100;
            roundedTotal = roundedValues.experiencia + roundedValues.educacion + roundedValues.cursos;            
        }    
        const profileData = {
            ...profile,
            profile_params: {
                params_profile_courses: roundedValues.experiencia,
                params_profile_education: roundedValues.educacion,
                params_profile_experience: roundedValues.cursos
            }
        }        
        if (profileUpdate) {
            lambdaProfile.put(id, profileData)
                .then(response => {            
                    setPage(3);
                    console.log(response)
                    setProfile_id(id)
                })
                .catch(error => {
                    console.error("Error:", error)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error
                    });
                    setLoading(false)
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            lambdaProfile.create(profileData)
                .then(response => {
                    console.log('Respuesta del servidor:', response);
                    setPage(3);
                    setProfile_id(response.data.profile_id)
                    console.log(response.data.profile_id)
                })
                .catch(error => {
                    console.error("Error:", error)
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: error
                    });
                    setLoading(false)
                })
                .finally(() => {
                    setLoading(false);
                });
        };
    };

    const handleChange = (key, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));
    };

    return (
        <div
            className="rounded-md  
        flex flex-col items-center justify-center 
        py-6 px-3 
        shadow-sm text-white 
        w-full max-w-md md:max-w-2xl lg:max-w-4xl mx-auto
        text-wrap break-words
        "
        >
            <form
                onSubmit={handleSubmit}
                className="flex flex-col items-center justify-center 
                bg-[#011A27] text-white p-5 rounded-lg"
                noValidate
            >
                <div className="w-full 
                text-center my-3
                text-2xl md:text-3xl lg:text-4xl">
                    Define la importancia de cada elemento</div>

                <div className="font-thin 
                text-lg text-center
                md:text-xl
                lg:text-2xl italic">
                    Dales un nivel de importancia de 1 (Poco importante) a 5 (Muy Importante)
                </div>

                <PointsPonderationInput
                    label={"Experiencia"}
                    value={formValues.experiencia}
                    onChange={handleChange}
                    field={Object.keys(formValues)[0]}
                />
                <PointsPonderationInput
                    label={"Educación"}
                    value={formValues.educacion}
                    onChange={handleChange}
                    field={Object.keys(formValues)[1]}
                />
                <PointsPonderationInput
                    label={"Cursos"}
                    value={formValues.cursos}
                    onChange={handleChange}
                    field={Object.keys(formValues)[2]}
                />
                <button
                    className={`w-full bg-[#F0810F] text-white text-3xl 
                rounded-md p-2 font-semibold hover:scale-105`}
                    type="submit"
                    disabled={loading}
                >
                    {
                        loading
                            ? <CircularProgress style={{ color: 'white', fontSize: 72 }} />
                            : "Enviar"
                    }
                </button>
            </form>
        </div>
    );
};

export default PonderationComponent;