import React, { useEffect, useState } from 'react'
import { CheckCircle } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { useSuscriptionValue } from '../BilleteraState/BilleteraContext'
const SubscriptionPlanComponent = ({ data, moreInfo, onClick, selected, preloadedImage }) => {
    const [isActive, setIsActive] = useState(false)
    const [descuento, setDescuento] = useState(false);
    const suscriptionValue = useSuscriptionValue()
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        setIsActive(selected && data.wallet_service_name === selected.producto);
    }, [selected, data]);

    useEffect(() => {
        if (data.wallet_service_promotional_price) {
            setDescuento(true);
        } else {
            setDescuento(false);
        }
    }, [data.wallet_service_promotional_price]);

    const promotionalPrice = descuento ? data.wallet_service_promotional_price : null;
    const discountAmount = descuento ? (Number(data.wallet_service_promotional_price) - Number(data.wallet_service_price)) : null;
    const hasSuscription = suscriptionValue.currentSuscription.name === data.wallet_service_name
    const calcSuscriptionEndDate = () => {
        if (!hasSuscription) return
        let startDate = new Date(suscriptionValue.currentSuscription.start_date)
        let end = new Date(startDate)

        if (data.wallet_service_name.split(" ").at(1) === "Premium") {
            end.setMonth(end.getMonth() + 1);
        } else if (data.wallet_service_name.split(" ").at(1) === "Platinum") {
            end.setFullYear(end.getFullYear() + 1);
        }

        setEndDate(end.toISOString().split('T')[0]);
    }
    useEffect(() => {
        hasSuscription && calcSuscriptionEndDate()
    }, [hasSuscription])
    
    const handleClick = () => {
        if (!hasSuscription) {
            onClick();
        }
    };
    return (
        <div className={`relative flex flex-col 
        items-center justify-center 
        p-0 m-0 top-0
        text-white
        hover:bg-[#F0810F]
        group 
        ${isActive ? " bg-[#FF8201]" : ""}
        ${hasSuscription ? 'bg-[#063852]' : 'bg-[#011A27] cursor-pointer'}
         transition-all duration-300 ease-in-out 
        `}
            onClick={handleClick}
        >
            <div className={`${data.wallet_service_name.split(" ").at(1) === "Premium" ? "bg-[#FF8201]" : "bg-[#E6DF44]"}  w-full
                flex flex-col items-center justify-center
                p-2 text-black
            `}>
                <div className='text-xl md:text-2xl lg:text-3xl flex flex-col items-center'>
                    <div className='font-light italic'>{data.wallet_service_name.split(" ").at(0)} </div>
                    <div className={`ml-1  font-semibold`}>{data.wallet_service_name.split(" ").at(1)}</div>
                </div>
            </div>

            <div className='relative flex flex-col items-center 
            justify-center mb-3 '>
                {promotionalPrice && (
                    <div className='line-through mt-1 text-purple-200 text-lg md:text-xl lg:text-2xl'>
                        ${promotionalPrice}
                    </div>
                )}
                {!promotionalPrice && <div className='mt-1 text-lg md:text-xl lg:text-2xl'>&nbsp;</div>}
                <div className='font-semibold mt-2
                    text-2xl md:text-3xl lg:text-4xl
                '>
                    ${data.wallet_service_price}
                </div>
                <div className='text-lg md:text-xl lg:text-2xl font-thin -translate-y-2 top-0'>
                    /{data.wallet_service_description.split(" ").at(-1)}
                </div>
                {discountAmount && (
                    <div className={`absolute rotate-[25deg] translate-x-14 text-base md:text-lg lg:text-xl
                        -translate-y-5 md:-translate-y-5 lg:-translate-y-4
                        bg-[#43ec3a] rounded-full px-2 text-[#063852] font-medium 
                        hover:scale-110 transition-all duration-300`}>
                        -${discountAmount}
                    </div>
                )}
            </div>

            <div className='w-full flex flex-col items-center p-2 '>
                <button className={`w-5/6 py-2 
                ${hasSuscription ? "text-white" : "bg-[#F0810F] text-black "}
                font-semibold
                hover:scale-105 transition-all duration-300
                text-xl md:text-2xl lg:text-3xl
                border-3 border-white
                shadow-lg shadow-black
                `}
                    disabled={hasSuscription}
                >
                    {hasSuscription ? "ACTIVO" : (isActive ? "Cancelar" : "Seleccionar")}
                </button>
                <div className='absolute'>
                    {hasSuscription && (
                        <div className='
                            text-[6px] translate-y-9
                            md:text-[8px] md:translate-y-9
                            lg:text-sm lg:translate-y-16
                        '>
                            Hasta: {`${endDate}`}
                        </div>
                    )}
                </div>
            </div>

            {
                moreInfo &&
                <div className='mt-2 flex flex-col text-left 
                text-base md:text-lg lg:text-xl
                p-2 font-thin'>
                    <div className='font-thin'>La {data.wallet_service_name} incluye:</div>
                    <div>
                        <CheckCircle fontSize='14' className='mr-2' />
                        {data.wallet_service_description}

                    </div>

                    <div className='w-full flex flex-col items-center'>
                        {preloadedImage ? (
                            <img
                                src={preloadedImage}
                                alt="Matchworking Logo"
                                className='w-20 h-20 text-center'
                            />
                        ) : (
                            <CircularProgress className='w-20 h-20 text-center' />
                        )}

                    </div>
                </div>
            }
        </div>
    )
}

export default SubscriptionPlanComponent
